import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import { portfolioSlick2 } from "../script";
import {Link} from "react-router-dom";

const list = [
    {
        image: 'shelfmate-poster-compact',
        category: 'Personal project',
        title: 'Shelfmate',
        page: '/my-work/shelfmate'
    },
    {
        image: 'drops-poster-compact',
        category: 'iOS Development',
        title: 'Drops',
        page: '/my-work/drops'
    },
    {
        image: 'crescendoes-poster-compact',
        category: 'Mobile Development & Design',
        title: 'Crescendoes',
        page: '/my-work/crescendoes'
    },
    {
        image: 'ava-poster-compact',
        category: 'iOS Development',
        title: 'AVA Finance',
        page: '/my-work/ava-finance'
    },
    {
        image: 'ourstory-poster-compact',
        category: 'Personal project',
        title: 'OurStory: Vent & Support',
        page: '/my-work/ourstory'
    },
    {
        image: 'golimo-poster-compact',
        category: 'Design',
        title: 'GoLimo',
        page: '/my-work/golimo'
    },
    {
        image: 'tareeqi-poster-compact',
        category: 'Mobile Development & Design',
        title: 'Tareeqi',
        page: '/my-work/tareeqi'
    },
    {
        image: 'groove-poster-compact',
        category: 'Mobile Development & Design',
        title: 'Groove Pro - Ecommerce',
        page: '/my-work/groove-pro'
    }
]

const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='My Work — Mario Mouris' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'My Work'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Portfolio Area */}
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            {list.map((value, index) => (
                                <div className="col-lg-4 col-md-6 col-12" key={index}>
                                    <div className="portfolio mt--40">
                                        <div className="thumbnail-inner">
                                            <div className={`thumbnail ${value.image}`}></div>
                                            <div className={`bg-blr-image ${value.image}`}></div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <p>{value.category}</p>
                                                <h4><a href={value.page}>{value.title}</a></h4>
                                                <div className="portfolio-button">
                                                    <a className="rn-btn" href={value.page}>View Details</a>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="link-overlay" to={value.page}></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <br />
                </div>
                {/* End Portfolio Area */}

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Portfolio;
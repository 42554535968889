// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

import Home from './home/Home';

// Element Layout
import Blog from "./elements/Blog";
import error404 from "./elements/error404";

// Blocks Layout
import Portfolio from "./blocks/Portfolio";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import DropsProjectDetails from './portfolio-projects/DropsProjectDetails';
import CrescendoesProjectDetails from './portfolio-projects/CrescendoesProjectDetails';
import AVAProjectDetails from './portfolio-projects/AVAProjectDetails';
import OurStoryProjectDetails from './portfolio-projects/OurStoryProjectDetails';
import GoLimoProjectDetails from './portfolio-projects/GoLimoProjectDetails';
import TareeqiProjectDetails from './portfolio-projects/TareeqiProjectDetails';
import GrooveProjectDetails from './portfolio-projects/GrooveProjectDetails';
import NavigationCoordinatorSwiftBlogDetails from './blog-posts/NavigationCoordinatorSwiftBlogDetails';
import NetworkLayerAlamofirePromisekitBlogDetails from './blog-posts/NetworkLayerAlamofirePromisekitBlogDetails';
import AdvancedDependencyInjectionBlogDetails from './blog-posts/AdvancedDependencyInjectionBlogDetails';
import ShelfmateProjectDetails from './portfolio-projects/ShelfmateProjectDetails';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>

                        {/* Projects */}
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work`} component={Portfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work/drops`} component={DropsProjectDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work/crescendoes`} component={CrescendoesProjectDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work/ava-finance`} component={AVAProjectDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work/ourstory`} component={OurStoryProjectDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work/golimo`} component={GoLimoProjectDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work/tareeqi`} component={TareeqiProjectDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work/groove-pro`} component={GrooveProjectDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/my-work/shelfmate`} component={ShelfmateProjectDetails}/>
                        
                        {/* Blog Posts  */}
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog/navigation-coordinator-pattern-swift`} component={NavigationCoordinatorSwiftBlogDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog/network-layer-alamofire-and-promisekit`} component={NetworkLayerAlamofirePromisekitBlogDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog/advanced-dependency-injection-in-swift`} component={AdvancedDependencyInjectionBlogDetails}/>
                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();